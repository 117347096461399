<template>
  <b-modal id="driverSignupDelivery"
           ref="driverPendingBookingInfo"
           lazy
           hide-footer
           size="lg"
           :title="modalTitle"
           @show="handleOpen"
           @hide="handleClose"
           @close="handleClose">
    <b-modal id="onDocsUpload"
             lazy
             no-fade
             no-close-on-backdrop
             no-close-on-esc
             hide-header-close
             header-bg-variant="white"
             size="md"
             :hide-footer="true"
             title="Atualização da Data de Validade da CNH">
      <p>Observamos uma divergência na data de validade em relação à imagem enviada. Por favor, confirme a data de
        validade da CNH:</p>
      <b-form>
        <label for="license_expired_at"
               class="font-weight-bold">Data vencimento:</label>
        <b-form-input id="license_expired_at"
                      v-model="form.license_expired_at"
                      type="date"/>
        <b-button class="mt-3"
                  block
                  variant="warning"
                  @click="changeCNHExpirationDate"> Confirmar
        </b-button>
      </b-form>
    </b-modal>
    <driver-header v-if="driver_data"
                   :id="driverId"
                   :modal="true"
                   :new-schedule-modal="isNewScheduleModal"/>

    <template v-if="driver_data && driver_data.id && driverType === 'NEW_DRIVER'">
      <b-card>
        <b-row>
          <b-col md="4">
            <strong>{{ plan.name }}</strong>
            <br/>
            <span class="small text-muted">{{ fuel_names }}</span>
          </b-col>
          <b-col v-if="pre_payment"
                 md="4">
            <p class="font-weight-bold">
              Pagamento caução
              <b-badge v-if="pre_payment.status"
                       :variant="pre_payment.status | transaction_status_variant">
                {{ pre_payment.status | transaction_status }}
                <template v-if="pre_payment.manual_confirmation"> (manual)</template>
              </b-badge>
            </p>
            <span class="break-line">Valor: {{ pre_payment.amount_paid | money_intl }}</span>
            <br/>
            <span v-if="pre_payment.payment_method" class="small text-muted">
              Pagamento com
              {{ pre_payment.payment_method.type | filterPaymentMethodType }}
            </span>
            <span v-else class="small text-danger">Aguardando forma de pagamento</span>
            <p
              v-if="pre_payment.payment_method.type === 'boleto' && pre_payment.status !== 'PAID' && pre_payment.manual_confirmation === false"
              class="m-0 mt-2">
              <b-link :href="`${invoice_url}${pre_payment.id}`" target="_blank">
                <i v-b-tooltip title="Acessar fatura" class="fa fa-link"/> Acessar fatura
              </b-link>
            </p>
          </b-col>

          <b-col v-if="first_payment" md="4">
            <p class="font-weight-bold">
              Pagamento 1º semana
              <b-badge v-if="first_payment.status" :variant="first_payment.status | transaction_status_variant">
                {{ first_payment.status | transaction_status }}
                <template v-if="first_payment.manual_confirmation"> (manual)</template>
              </b-badge>
            </p>
            <span class="break-line">Valor: {{ first_payment.amount_paid | money_intl }}</span>
            <br/>
            <span v-if="first_payment.payment_method" class="small text-muted">
              Pagamento com
              {{ first_payment.payment_method.type | filterPaymentMethodType }}
            </span>
            <span v-else class="small text-danger">Aguardando forma de pagamento</span>
            <p
              v-if="first_payment.payment_method.type === 'boleto' && first_payment.status !== 'PAID' && first_payment.manual_confirmation === false"
              class="m-0 mt-2">
              <b-link :href="`${invoice_url}${first_payment.id}`" target="_blank">
                <i v-b-tooltip title="Acessar fatura" class="fa fa-link"/>
                {{ $i18n.t('modalVehicleDelivery.texts.accessInvoice') }}
              </b-link>
            </p>
          </b-col>
        </b-row>
      </b-card>

      <b-card v-if="hasBooking">
        <b-row>
          <b-col md="12" class="mt-sm-3 mt-md-0">
            <p>
              <strong>{{ $i18n.t('drivers.labels.reasonSchedule') }}</strong>
            </p>
            <p>{{ reasonSchedule }}</p>
            <b-col/>
          </b-col>
        </b-row>
      </b-card>

      <b-card v-if="hasBooking">
        <b-row>
          <b-col :md="showCarDocument ? '6' : '4'">
            <p>{{ `${$t('modalVehicleDelivery.texts.contractPhoto')}:` }}</p>
            <upload-image v-model="contract_image"
                          :file-upload-props="{ type: 'contract', source: `${booking.id}_${getCurrentTimestamp()}` }"
                          auto-upload
                          :help-text="$i18n.t('modalVehicleDelivery.texts.phraseClickOnBox')"/>
          </b-col>
          <b-col :md="showCarDocument ? '6' : '4'">
            <p>{{ `${$t('modalVehicleDelivery.texts.licenseNumberPhoto')}:` }}</p>
            <upload-image v-model="cnh_image" auto-upload file-upload-endpoint="/docs" file-upload-name="data"
                          :file-upload-props="{ documentType: 'cnh_photo', driverId: driverId }"
                          :help-text="$i18n.t('modalVehicleDelivery.texts.phraseClickOnBox')"
                          @result="onResultUpload($event)"/>
          </b-col>
          <b-col v-if="showCarDocument" :md="showCarDocument ? '6' : '4'">
            <p>{{ `${$t('modalVehicleDelivery.texts.vehicleDocument')}:` }}</p>
            <upload-image v-model="car_document_image"
                          :file-upload-props="{ type: 'car_document', source: selected_car }" auto-upload
                          :help-text="$i18n.t('modalVehicleDelivery.texts.phraseClickOnBox')"/>
          </b-col>
          <b-col :md="showCarDocument ? '6' : '4'">
            <p>{{ `${$t('modalVehicleDelivery.texts.proofAddressPhoto')}:` }}</p>
            <upload-image v-model="address_image"
                          auto-upload
                          file-upload-endpoint="/docs"
                          file-upload-name="data"
                          :file-upload-props="{ documentType: 'address_photo', driverId: driverId }"
                          :help-text="$i18n.t('modalVehicleDelivery.texts.phraseClickOnBox')"
                          @result="onResultUpload($event)"/>
          </b-col>
        </b-row>
      </b-card>

      <b-card v-if="booking">
        <b-row>
          <b-col md="4">
            <p>{{ $i18n.t('modalVehicleDelivery.texts.forecastDelivery') }}</p>
            <h5 v-if="currentDeliveryScheduleDate">
              {{ currentDeliveryScheduleDate | format_date('DD/MM/YYYY HH:mm') }}
            </h5>
            <h5 v-else>
              {{ $i18n.t('modalVehicleDelivery.texts.noShowDelivery') }}
            </h5>

            <b-button id="driver-view-link-generate-contract"
                      class="w-100 white-color"
                      variant="warning"
                      size="lg"
                      @click="openModalRescheduleDelivery">
              {{ $i18n.t('modalVehicleDelivery.texts.rescheduleDelivery') }}
            </b-button>

            <br/>

            <p class="mt-3">{{ $i18n.t('modalVehicleDelivery.texts.rescheduleNumber') }} {{ scheduleCount }}</p>
          </b-col>

          <b-col md="4" sm="12" class="mt-sm-3 mt-md-0">
            <b-form-group :label="$i18n.t('modalVehicleDelivery.texts.availableVehicles')">
              <car-available-select v-model="selected_car"
                                    :plan-id="driver_data.booking.plan.id"/>
              <b-form-group v-if="car"
                            :label="`${$i18n.t('modalVehicleDelivery.texts.vehicleLinked')}:`"
                            class="mt-3">
                <h5 class="mb-0 font-weight-bold">
                  {{ car.license_plate }}
                </h5>
                <p>
                  <span class="text-muted">{{ car.brand.name }} {{ car.model.name }}</span>
                </p>
              </b-form-group>
            </b-form-group>
          </b-col>

          <b-col md="4"
                 sm="12"
                 class="mt-sm-3 mt-md-0">
            <b-form-group label="">
              <b-button id="driver-view-link-car-button"
                        variant="success"
                        class="w-100"
                        size="lg"
                        :disabled="!selected_car || isDriverLicenseExpired"
                        @click="$_confirmLinkCar">
                {{ $i18n.t('modalVehicleDelivery.words.link') }}
              </b-button>

              <b-button v-if="car"
                        id="driver-view-link-generate-contract"
                        class="mt-3 w-100"
                        variant="primary"
                        :disabled="block_ui"
                        size="lg"
                        @click="$_genContract">
                <i v-if="block_ui" class="fa fa-spin fa-refresh" />
                {{ $i18n.t('modalVehicleDelivery.texts.printContract') }}
              </b-button>

              <b-button v-if="car"
                        id="driver-view-link-generate-contract"
                        class="mt-3 w-100 white-color"
                        variant="warning"
                        size="lg"
                        @click="unlinkCar">
                {{ $i18n.t('modalVehicleDelivery.texts.unlinkCar') }}
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col class="text-center mb-4">
          <b-button id="driver-view-cancel-booking"
                    class="font-weight-bold"
                    variant="danger"
                    :disabled="!cancelButtonVisible"
                    @click="$_confirmCancelDelivery">
            {{ $i18n.t('modalVehicleDelivery.button.cancelDelivery') }}
          </b-button>
        </b-col>

        <b-col class="text-center mb-4">
          <b-button id="driver-view-activate-booking"
                    class="font-weight-bold"
                    variant="success"
                    :disabled="!is_signatures_available"
                    title="Faça o upload da foto do contrato e da CNH"
                    @click="activeBooking">
            Ativar Booking
          </b-button>
        </b-col>
      </b-row>
    </template>

    <template v-else-if="driver_data && driver_data.id && driverType !== 'NEW_DRIVER'">
      <b-row>
        <b-col md="6">
          <b-card>
            <span class="font-weight-bold text-muted">Tipo de agendamento</span><br/>
            <span class="font-weight-bold">{{ driver_data.type | format_schedule('type') }}</span>
            ><br/><br/>

            <span class="font-weight-bold text-muted">Detalhamento</span><br/>
            <span class="font-weight-bold">{{ driver_data.comments }}</span><br/><br/>

            <span class="font-weight-bold text-muted">Pátio</span><br/>
            <span class="font-weight-bold">{{ completePlace }}</span><br/><br/>

            <span class="font-weight-bold text-muted">Data</span><br/>
            <span class="font-weight-bold">{{
                currentDeliverySchedule | format_date('DD/MM/YYYY - HH:mm')
              }}</span><br/><br/>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-row>
            <b-col md="12" class="text-center">
              <b-button id="driver-view-link-generate-contract" class="w-150 white-color" variant="primary" size="md"
                        @click="$_accesslocations">
                {{ $i18n.t('modalVehicleDelivery.texts.accesslocations') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="!shouldHideButtonBasedOnType">
        <b-col class="text-center mb-12 button-list">
          <b-button id="driver-view-activate-booking" class="font-weight-bold" variant="dark"
                    :disabled="!can_activate_booking" @click="$_confirmNotAttend">
            {{ $i18n.t('modalVehicleDelivery.texts.notAttend') }}
          </b-button>

          <b-button id="driver-view-activate-booking" class="font-weight-bold" variant="danger"
                    :disabled="!can_activate_booking" @click="$_confirmeCancelDelivery">
            {{ $i18n.t('modalVehicleDelivery.texts.cancelDelivery') }}
          </b-button>

          <b-button id="driver-view-activate-booking" variant="success" :disabled="!can_activate_booking"
                    @click="$_confirmFinishDelivery">
            {{ newTextButton }}
          </b-button>
        </b-col>
      </b-row>
    </template>
    <modal-driver-signup-cancel-delivery :driver="driver_data"
                                         :modal-title="newModalCancelTitle"
                                         :page="page"
                                         @returnCancelDelivery="$_cancelDelivery($event)"
    />
    <modal-fleet-compare-signatures :driver-document-url="get_image_url"
                                    :contract-url="contract_image"
                                    :handle-yes="$_confirmFinishBooking"
                                    :driver-id="driverId"
                                    :booking-id="bookingId"
    />
  </b-modal>
</template>

<script>
import CarAvailableSelect from '@components/select/car-available';
import gql from 'graphql-tag';
import DriverHeader from '@components/drivers/header';
import UploadImage from '@components/shared/upload-image';
import DRIVER_GET from '@graphql/driver/queries/get.gql';
import PLACE_GET from '@graphql/place/queries/get.gql';
import UNLINK_CAR from '@graphql/booking/mutations/unlink-car.gql';
import UPDATE_LICENSE_EXPIRED_AT from '@graphql/driver/mutations/update-license-expired-at.gql';
import CARS_LIST_AVAILABLE from '@graphql/car/queries/list-available.gql';
import UPDATE_BOOKING_INFO from '@graphql/booking/mutations/update-booking-info.gql';
import ACTIVE_BOOKING_SIGNUP_FLOW from '@graphql/booking/mutations/active-booking-signup-flow.gql';
import FINALLY_SCHEDULE_DELIVERY from '@graphql/schedules/mutations/finally-schedule-delivery.gql';
import NO_SHOW_SCHEDULE_DELIVERY from '@graphql/schedules/mutations/no-show-schedule-delivery.gql';
import FINALLY_SCHEDULE_DEVOLUTION from '@graphql/schedules/mutations/finally-schedule-devolution.gql';
import FINALLY_SCHEDULE_INSPECTION from '@graphql/schedules/mutations/finally-schedule-inspection.gql';
import NO_SHOW_SCHEDULE_DEVOLUTION from '@graphql/schedules/mutations/no-show-schedule-devolution.gql';
import NO_SHOW_SCHEDULE_INSPECTION from '@graphql/schedules/mutations/no-show-schedule-inspection.gql';
import CANCEL_DELIVERY from '@graphql/schedules/mutations/cancel-delivery.gql';
import UPDATE_CAR_DOCUMENT from '@graphql/driver-documents/mutations/update-car-document.gql';
import APPROVE_DOCUMENTATION from '@graphql/driver-documents/mutations/new-approve-documentation.gql';
import UPDATE_DRIVER_CNH from '@graphql/driver-documents/mutations/update-cnh-doc-driver.gql';
import ModalDriverSignupCancelDelivery from '@components/drivers/signup/modals/cancel-delivery';
import ModalFleetCompareSignatures from '@components/fleet/modals/fleet-compare-signatures';
import { mapActions, mapGetters } from 'vuex';
import { isBrazil } from '@utils/helper-region';
const { parse, isBefore } = require('date-fns');

export default {
  name: 'ModalDriverSignupDelivery',
  components: {
    CarAvailableSelect,
    DriverHeader,
    UploadImage,
    ModalDriverSignupCancelDelivery,
    ModalFleetCompareSignatures
  },
  props: {
    driver: {
      type: Object,
      required: true,
    },
    page: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => ({
    onResult: null,
    driver_data: null,
    schedule_date: null,
    unlinkCarMutation: UNLINK_CAR,
    invoice_url: process.env.KOVI_FATURA_URL,
    driver_get_query: DRIVER_GET,
    car_list_query: CARS_LIST_AVAILABLE,
    place_get_query: PLACE_GET,
    update_booking_mutation: UPDATE_BOOKING_INFO,
    activate_booking_mutation: ACTIVE_BOOKING_SIGNUP_FLOW,
    cancel_delivery_mutation: CANCEL_DELIVERY,
    cars: [],
    selected_car: '',
    loading: false,
    block_ui: false,
    contract_image: null,
    cnh_image: null,
    car_document_image: null,
    address_image: null,
    scheduleCount: 0,
    koviStaff: '',
    form: {
      license_expired_at: '',
    },
  }),
  beforeDestroy() {
    this.setDeliverySchedule({});
  },
  mounted() {
    this.koviStaff = this.$store.getters['user/attributes'].email;
  },
  computed: {
    ...mapGetters({
      deliveryScheduleId: 'schedule/deliveryScheduleId',
      deliveryScheduleData: 'schedule/deliveryScheduleData',
      deliverySchedule: 'schedule/deliverySchedule',
    }),
    ...mapGetters('driver', {
      profile: 'header',
    }),
    driverStep() {
      return this.profile ? this.profile.step : '';
    },
    shouldHideButtonBasedOnType() {
      return ['EXCHANGE_MAINTENANCE'].includes(this.deliverySchedule?.type);
    },
    bookingId() {
      return this.booking?.id;
    },
    cancelButtonVisible() {
      const hasStep = ['DELIVERY_PENDING', 'DELIVERY_SCHEDULED', 'DELIVERY_NOSHOW'].includes(this.driverStep);
      const hasCognitoGroup = this.$store.getters['user/groups'].includes('RULE:CANCEL:DELIVERY');

      return !!(hasStep && hasCognitoGroup);
    },
    currentDeliverySchedule() {
      return this.deliverySchedule.scheduled_at;
    },
    currentDeliveryScheduleDate() {
      return this.deliveryScheduleData.scheduled_at;
    },
    today() {
      return this.$moment().format();
    },
    newTextButton() {
      return this.page === 'delivery' ? this.$i18n.t('modalVehicleDelivery.texts.finishDelivery') : this.$i18n.t('modalVehicleDelivery.texts.finishDevolution');
    },
    newModalTitle() {
      return this.page === 'delivery' ? this.$i18n.t('modalVehicleDelivery.texts.finishDelivery') : this.$i18n.t('modalVehicleDelivery.texts.finishDevolution');
    },
    newModalCancelTitle() {
      return this.page === 'delivery' ? this.$i18n.t('modalVehicleDelivery.texts.cancelDelivery') : this.$i18n.t('modalVehicleDelivery.texts.finishDevolution');
    },
    isNewScheduleModal() {
      return !!(this.driver_data && this.driver_data.type && this.driver_data.type !== 'NEW_DRIVER');
    },
    driverId() {
      return this.driver.driver ? this.driver.driver.id : this.driver.id;
    },
    driverType() {
      if (this.driver_data && this.driver_data.type && this.driver_data.type !== 'NEW_DRIVER') {
        return this.driver_data.type;
      }
      return 'NEW_DRIVER';
    },
    completePlace() {
      if (this.driver_data && this.driver_data.place && this.driver_data.place.complete_place) {
        return this.driver_data.place.complete_place;
      }
      return '';
    },
    booking() {
      if (this.driver_data && this.driver_data.booking) {
        return this.driver_data.booking;
      }
      return null;
    },
    car() {
      if (this.booking) {
        return this.booking.car;
      }
      return null;
    },
    plan() {
      return this.booking ? this.booking.plan : {};
    },
    fuel_names() {
      if (this.plan && this.plan.fuel) {
        return this.plan.fuel.map(item => item.name).join(', ');
      }
      return '';
    },
    pre_payment() {
      if (this.booking && this.booking.transactions && Array.isArray(this.booking.transactions.items)) {
        return this.booking.transactions.items.find(item => ['PRE_PAYMENT', 'PRE_PAYMENT_RTO', 'PRE_PAYMENT_PROMOTIONAL'].includes(item.kind));
      }
      return null;
    },
    first_payment() {
      if (this.booking && this.booking.transactions && Array.isArray(this.booking.transactions.items)) {
        return this.booking.transactions.items.find(item => item.kind === 'FIRST_PAYMENT');
      }
      return null;
    },
    get_image_url() {
      return this.cnh_image?.url
    },
    can_activate_booking() {
      return true;
    },
    is_signatures_available() {
      if (!isBrazil()) return true
      return this.contract_image && this.cnh_image?.url;
    },
    modalTitle() {
      if (this.driverType === 'NEW_DRIVER') {
        return this.$i18n.t('modalVehicleDelivery.titles.modal');
      }
      return this.$i18n.t('modalVehicleDelivery.titles.modalDelivery');
    },
    hasBooking() {
      return !!(this.driver && this.booking);
    },
    reasonSchedule() {
      return this.driver.schedule[0].comments || this.driver.comments;
    },
    isDriverLicenseExpired() {
      if (!isBrazil()) return false;

      const licenseExpiredAt = this.driver?.license_expired_at || this.driver?.driver?.license_expired_at;

      if (!licenseExpiredAt) {
        console.warn('License expiration date not available');
        return true;
      }

      const expirationDate = this.$moment(licenseExpiredAt).endOf('day');
      const today = this.$moment().endOf('day');
      const thirtyDaysAfterExpirationDate = this.$moment(expirationDate).add(30, 'days').endOf('day');

      // considers expired 30 days after the actual expiration date
      return today.isAfter(thirtyDaysAfterExpirationDate);
    },
    showCarDocument() {
      return !isBrazil()
    }
  },
  watch: {
    driver(newDriver) {
      this.$_reset();
      this.driver_data = null;
      if (newDriver) {
        this.$_getData();
      }
    },
    cnh_image(newImage) {
      if (newImage && newImage?.id) {
        this.$store.dispatch('driverDocument/approve', newImage.id);
      }
    },
    address_image(newImage) {
      if (newImage && newImage?.id) {
        this.$store.dispatch('driverDocument/approve', newImage.id);
      }
    },
  },
  methods: {
    ...mapActions({
      setDeliveryScheduleId: 'schedule/setDeliveryScheduleId',
      setDeliveryScheduleData: 'schedule/setDeliveryScheduleData',
      setDeliverySchedule: 'schedule/setDeliverySchedule',
    }),
    getCurrentTimestamp() {
      const now = new Date();
      const pad = num => String(num).padStart(2, '0');
      return `${now.getFullYear()}${pad(now.getMonth() + 1)}${pad(now.getDate())}${pad(now.getHours())}${pad(now.getMinutes())}${pad(now.getSeconds())}`;
    },
    onResultUpload($event) {
      this.onResult = $event;

      if (this.onResult) {
        this.$apollo
          .mutate({
            mutation: UPDATE_DRIVER_CNH,
            variables: {
              driverId: this.driverId,
              documentId: this.onResult.id,
            },
          })
          .then(() => {
            const cnhExpired = isBefore(parse(this.driver_data.license_expired_at, 'yyyy-MM-dd', new Date()), new Date());
            if (cnhExpired) {
              this.$root.$emit('bv::show::modal', 'onDocsUpload');
            }
          });
      }
    },
    checkScheduled() {
      const item = this.driver_data.schedule.filter(function (obj) {
        return obj.type === 'NEW_DRIVER' && obj.status === 'SCHEDULED';
      });
      if (item.length > 0 && item[0].id) {
        this.setDeliveryScheduleId(item[0].id);
        this.setDeliveryScheduleData(item[0]);
      }
    },
    $_accesslocations() {
      this.$router.push({ path: `profile/${this.driver_data.driver.id}/bookings` });
    },
    $_notAttend() {
      // TODO na modal de delivery tem o codigo de devolution
      const currentMutation =
        this.page === 'delivery'
          ? this.no_show_schedule_delivery_mutation
          : this.page === 'devolution'
            ? this.no_show_schedule_devolution_mutation
            : this.no_show_schedule_inspection_mutation;

      return this.$apollo
        .mutate({
          mutation: currentMutation,
          variables: {
            input: {
              id: this.driver_data.schedule,
            },
          },
        })
        .then(() => {
          return true;
        })
        .catch(() => {
          return Promise.reject(this.$i18n.t('modalVehicleDelivery.texts.couldNotLinkThisVehicle'));
        });
    },
    $_confirmNotAttend() {
      const configSwal = {
        type: 'warning',
        title: this.newModalTitle,
        text: this.$i18n.t('modalVehicleDelivery.texts.noShowConfirmPhrase'),
        showConfirmButton: true,
        confirmButtonText: this.$i18n.t('modalVehicleDelivery.words.finish'),
        confirmButtonClass: 'driver-container-finish-booking-button',
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('modalVehicleDelivery.words.close'),
        showLoaderOnConfirm: true,
        preConfirm: () => this.$_notAttend(),
      };
      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;
          const configSwalSuccess = {
            type: 'success',
            title: this.$i18n.t('modalVehicleDelivery.texts.driverNoShowSuccess'),
            timer: 2000,
            text: '',
            showConfirmButton: false,
            showCancelButton: false,
          };
          this.$swal(configSwalSuccess).then(() => {
            this.$emit('finished');
          });
        })
        .catch(err => {
          this.$log.logError(err);
          const configSwalFail = {
            type: 'error',
            title: this.$i18n.t('modalVehicleDelivery.texts.driverNoShowError'),
            text: err,
          };
          this.$swal(configSwalFail);
        });
    },
    $_confirmeCancelDelivery() {
      this.$root.$emit('bv::show::modal', 'driverSignupCancelDelivery');
    },
    $_cancelDelivery(event) {
      if (event === 'success') this.$emit('finished');
    },
    $_finishDelivery() {
      const currentMutation =
        this.page === 'delivery'
          ? this.finally_schedule_delivery_mutation
          : this.page === 'devolution'
            ? this.finally_schedule_devolution_mutation
            : this.finally_schedule_inspection_mutation;

      return this.$apollo
        .mutate({
          mutation: currentMutation,
          variables: {
            input: {
              id: this.driver_data.schedule,
              car: this.selected_car,
            },
          },
        })
        .then(a => {
          return true;
        })
        .catch(() => {
          return Promise.reject(this.$i18n.t('modalVehicleDelivery.texts.driverFinishError'));
        });
    },
    openModalRescheduleDelivery() {
      this.$root.$emit('bv::show::modal', 'driverSignupRescheduling');
    },
    getScheduleCount() {
      const _self = this;
      return this.$apollo
        .query({
          query: gql`
            query BookingByDriver($id: ID!) {
              booking(id: $id) {
                schedule_count
              }
            }
          `,
          variables: { id: this.booking.id || null },
        })
        .then(response => {
          _self.scheduleCount = response.data.booking.schedule_count != null ? response.data.booking.schedule_count : 0;
        })
        .catch(error => {
          console.log(error);
        });
    },

    async changeCNHExpirationDate() {
      this.$root.$emit('bv::hide::modal', 'onDocsUpload');
      await this.handleGqlUpdateLicenseExpiredAt();
    },

    async handleGqlUpdateLicenseExpiredAt() {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_LICENSE_EXPIRED_AT,
          variables: {
            driverId: this.driver_data.id,
            licenseExpiredAt: this.form.license_expired_at,
          },
        });
        await this.$swal({
          type: 'success',
          title: 'Data de validade da CNH atualizada com sucesso',
          showConfirmButton: false,
          timer: 2500,
        });
      } catch (err) {
        await this.$swal({
          type: 'error',
          title: 'Falha ao atualizar a Data de validade da CNH',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },

    unlinkCar() {
      return this.$apollo
        .mutate({
          mutation: this.unlinkCarMutation,
          variables: {
            input: {
              booking: this.booking.id,
              schedule: this.deliveryScheduleId,
            },
          },
        })
        .then(() => {
          this.driver_data.booking.car = null;
          this.car_document_image = null;
          this.$_getData();
          return true;
        })
        .catch(error => {
          console.log(error);
        });
    },

    clearData() {
      this.driver_data = null;
      this.schedule_date = null;
      this.invoice_url = process.env.KOVI_FATURA_URL;
      this.driver_get_query = DRIVER_GET;
      this.update_booking_mutation = UPDATE_BOOKING_INFO;
      this.activate_booking_mutation = ACTIVE_BOOKING_SIGNUP_FLOW;
      this.finally_schedule_delivery_mutation = FINALLY_SCHEDULE_DELIVERY;
      this.no_show_schedule_delivery_mutation = NO_SHOW_SCHEDULE_DELIVERY;
      this.finally_schedule_devolution_mutation = FINALLY_SCHEDULE_DEVOLUTION;
      this.no_show_schedule_devolution_mutation = NO_SHOW_SCHEDULE_DEVOLUTION;
      this.no_show_schedule_inspection_mutation = NO_SHOW_SCHEDULE_INSPECTION;
      this.finally_schedule_inspection_mutation = FINALLY_SCHEDULE_INSPECTION;
      this.update_car_document_mutation = UPDATE_CAR_DOCUMENT;
      this.cancel_delivery_mutation = CANCEL_DELIVERY;
      this.selected_car = '';
      this.loading = false;
      this.contract_image = null;
      this.cnh_image = null;
      this.car_document_image = null;
      this.address_image = null;
    },
    handleOpen() {
      this.window_closed = false;
    },
    handleClose() {
      this.window_closed = true;
    },
    $_reset() {
      this.clearData();
    },
    $_getData() {
      this.$_reset();

      if (this.driver.type && this.driver.type !== 'NEW_DRIVER') {
        let updatePlace = this.driver;
        let place = this.driver.place;

        if (place.address_street_number) {
          updatePlace.place.complete_place = `${place.name} (${place.address_street_name}, ${place.address_street_number}. ${place.address_neighborhood}. ${place.address_city}. ${place.address_state})`;
        } else {
          updatePlace.place.complete_place = `${place.name} (${place.address_street_name}, ${place.address_neighborhood}. ${place.address_city}. ${place.address_state})`;
        }

        this.driver_data = updatePlace;
      } else {
        let currentDriveId = this.driverId;

        this.$apollo
          .query({
            query: this.driver_get_query,
            variables: {
              id: currentDriveId,
            },
          })
          .then(response => {
            this.driver_data = response.data.driver;

            // set values only  if exists
            if (this.car) {
              this.selected_car = this.car.id;
              if (this.car.document_url) this.car_document_image = this.car.document_url;
            }

            if (this.driver_data.cnh_doc) this.cnh_image = this.driver_data.cnh_doc.url;
          })
          .then(() => {
            this.checkScheduled();
          })
          .catch(err => {
            throw new Error(err);
          })
          .finally(() => {
            if (this.booking) this.getScheduleCount();
          });
      }
    },
    $_confirmLinkCar() {
      const configSwal = {
        type: 'warning',
        title: this.$i18n.t('modalVehicleDelivery.titles.linkVehicle'),
        text: this.$i18n.t('modalVehicleDelivery.texts.phraseDoYouWihLinkThisVehicle'),
        showConfirmButton: true,
        confirmButtonText: this.$i18n.t('modalVehicleDelivery.words.link'),
        confirmButtonClass: 'driver-container-link-car-button',
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('modalVehicleDelivery.words.close'),
        showLoaderOnConfirm: true,
        preConfirm: () =>
          new Promise((resolve, reject) => {
            if (!this.selected_car) reject(this.$i18n.t('modalVehicleDelivery.texts.selectaVehicleToLink'));
            this.$_linkCar().then(resolve(true)).catch(reject(false));
          }),
      };

      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;

          const configSwalSuccess = {
            type: 'success',
            title: this.$i18n.t('modalVehicleDelivery.texts.theVehicleWasLinkedWithSuccessful'),
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
            text: '',
          };

          this.$swal(configSwalSuccess);
        })
        .catch(err => {
          const configSwalFail = {
            type: 'error',
            title: this.$i18n.t('modalVehicleDelivery.texts.couldNotLinkThisVehicle'),
            showCancelButton: false,
            showConfirmButton: false,
            text: err,
            timer: 2000,
          };
          this.$swal(configSwalFail);
        });
    },
    $_linkCar() {
      if (!this.booking.id) return Promise.reject(this.$i18n.t('modalVehicleDelivery.texts.couldNotLinkThisVehicle'));

      return this.$apollo
        .mutate({
          mutation: this.update_booking_mutation,
          variables: {
            id: this.booking.id,
            car: this.selected_car,
            schedule: this.deliveryScheduleId,
          },
        })
        .then(response => {
          if (response && response.data && response.data.updateBookingInfo) {
            this.driver_data.booking.car = response.data.updateBookingInfo.car;
            this.car_document_image = this.car.document_url;
          }
          this.$_getData();
          return true;
        })
        .catch(() => {
          return Promise.reject(this.$i18n.t('modalVehicleDelivery.texts.couldNotLinkThisVehicle'));
        });
    },
    $_genContract() {
      // update started at from booking for today
      this.$apollo.mutate({
        mutation: this.update_booking_mutation,
        variables: {
          id: this.booking.id,
          started_at: this.$moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        },
      });

      if (isBrazil()) {
        this.block_ui = true;
        const maxTentativas = 3; // tentativa inicial + 2 retentativas
        let tentativa = 0;

        const tentarCarregarContrato = async () => {
          try {
            const res = await this.$fleetRegulationProvider.get(
              `drivers/booking/${this.booking.id}/contract`,
              { headers: { 'x-is-pdf': true, 'x-kovi-staff': this.koviStaff } }
            );

            this.block_ui = false;
            const pdf = `data:application/pdf;base64,${res.data.image}`;
            const fileName = `Contrato ${this.booking.id}`;
            const pdfWindow = window.open();
            pdfWindow.document.write(
              `<html><head><title>${fileName}</title></head><body><embed width='100%' height='100%' src='${pdf}' type='application/pdf'></embed></body></html>`
            );
          } catch (err) {
            tentativa++;
            if (tentativa < maxTentativas) {
              console.log(`Tentativa ${tentativa} falhou, tentando novamente...`);
              return await tentarCarregarContrato();
            }

            this.block_ui = false;
            console.error('Erro ao carregar contrato após 3 tentativas:', err);
            this.$swal({
              type: 'error',
              title: this.$i18n.t('modalBooking.texts.errorHasOccurred'),
              text: this.$i18n.t('modalBooking.texts.errorLoadingContract'),
              showConfirmButton: false,
              timer: 2500,
            });
          }
        };

        return tentarCarregarContrato();
      }

      this.$nextTick(() => {
        let top = window.screen.height - 700;
        top = top > 0 ? top / 2 : 0;

        let left = window.screen.width - 1000;
        left = left > 0 ? left / 2 : 0;

        const printWindow = window.open('', this.$i18n.t('modalVehicleDelivery.texts.printContract'), 'width=1000,height=700,top=' + top + ',left=' + left);
        printWindow.document.write(
          `<div style="font-family:Arial,sans-serif;font-size:30px;padding:200px 0;text-align:center">${this.$i18n.t(
            'modalVehicleDelivery.texts.loadingContract'
          )}</div>`
        );
        printWindow.location.href = '/drivers/profile/' + this.driverId + '/bookings/' + this.booking.id + '/print';
        printWindow.moveTo(left, top);
        printWindow.focus();
      });
    },
    $_confirmFinishDelivery() {
      const configSwal = {
        type: 'warning',
        title: this.newModalTitle,
        text: this.$i18n.t('modalVehicleDelivery.texts.finishConfirmPhrase'),
        showConfirmButton: true,
        confirmButtonText: this.$i18n.t('modalVehicleDelivery.words.finish'),
        confirmButtonClass: 'driver-container-finish-booking-button',
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('modalVehicleDelivery.words.close'),
        showLoaderOnConfirm: true,
        preConfirm: () => this.$_finishDelivery(),
      };
      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;
          const configSwalSuccess = {
            type: 'success',
            title: this.$i18n.t('modalVehicleDelivery.texts.driverFinishSuccess'),
            timer: 2000,
            text: '',
            showConfirmButton: false,
            showCancelButton: false,
          };
          this.$swal(configSwalSuccess).then(() => {
            this.$emit('finished');
          });
        })
        .catch(err => {
          this.$log.logError(err);
          const configSwalFail = {
            type: 'error',
            title: this.$i18n.t('modalVehicleDelivery.texts.driverFinishError'),
            text: err,
          };
          this.$swal(configSwalFail);
        });
    },
    $_confirmCancelDelivery() {
      const configSwal = {
        type: 'warning',
        title: this.$i18n.t('modalVehicleDelivery.texts.cancelRental'),
        text: this.$i18n.t('modalVehicleDelivery.texts.toCancelDelivery'),
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('modalVehicleDelivery.words.close'),
        showConfirmButton: true,
        confirmButtonText: this.$i18n.t('modalVehicleDelivery.words.confirm'),
        confirmButtonClass: 'driver-container-finish-booking-button',
        showLoaderOnConfirm: true,
        preConfirm: () => this.cancelDelivery(),
      };
      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;
          const configSwalSuccess = {
            type: 'success',
            title: this.$i18n.t('modalVehicleDelivery.texts.deliveryCanceled'),
            timer: 2000,
            text: '',
            showCancelButton: false,
            showConfirmButton: false,
          };
          this.$swal(configSwalSuccess).then(() => {
            this.$emit('finished');
          });
        })
        .catch(err => {
          this.$log.logError(err);
          const configSwalFail = {
            type: 'error',
            title: this.$i18n.t('modalVehicleDelivery.texts.cancelRental'),
            text: this.$i18n.t('modalVehicleDelivery.texts.couldNotCancelDelivery'),
          };
          this.$swal(configSwalFail);
        });
    },
    activeBooking() {
      isBrazil()
        ? this.$root.$emit('bv::show::modal', 'fleetCompareSignatures')
        : this.$_confirmFinishBooking();
    },
    $_confirmFinishBooking() {
      const configSwal = {
        type: 'warning',
        title: this.$i18n.t('modalVehicleDelivery.texts.finishDelivery'),
        text: this.$i18n.t('modalVehicleDelivery.texts.toFinishDeliveryWillNoLongerBePossibleEditTheBooking'),
        showConfirmButton: true,
        confirmButtonText: this.$i18n.t('modalVehicleDelivery.words.finish'),
        confirmButtonClass: 'driver-container-finish-booking-button',
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('modalVehicleDelivery.words.close'),
        showLoaderOnConfirm: true,
        preConfirm: () => this.$_finishBooking(),
      };
      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;
          const configSwalSuccess = {
            type: 'success',
            title: this.$i18n.t('modalVehicleDelivery.texts.driverActive'),
            timer: 2000,
            text: '',
            showConfirmButton: true,
            confirmButtonText: this.$i18n.t('modalVehicleDelivery.texts.goToProfile'),
            showCancelButton: true,
            cancelButtonText: this.$i18n.t('modalVehicleDelivery.texts.deliveryAnotherVehicle'),
            onAfterClose: () => {
              this.$emit('finished');
            },
          };
          this.$swal(configSwalSuccess).then(val => {
            if (val.value) {
              this.$router.push({
                path: '/drivers/active',
              });
            } else {
              this.$router.push({
                path: '/drivers/scheduled-delivery',
              });
            }
          });
        })
        .catch(err => {
          const configSwalFail = {
            type: 'error',
            title: this.$i18n.t('modalVehicleDelivery.texts.couldNotPossibleActiveDriver'),
            text: err,
          };
          this.$swal(configSwalFail);
        });
    },
    async cancelDelivery() {
      const booking = this.booking ? this.booking.id : null;
      const car = this.car ? this.car.id : null;
      const schedule = this.deliveryScheduleId ? this.deliveryScheduleId : null;

      return this.$apollo
        .mutate({
          mutation: this.cancel_delivery_mutation,
          variables: {
            input: {
              driver: this.driverId,
              booking,
              car,
              schedule,
            },
          },
        })
        .then(response => {
          const data = response.data.rentalCancel;

          let hasCar = false;
          let hasSchedule = false;

          if ((!car && !data.car) || (car && data.car)) {
            hasCar = true;
          }

          if ((!schedule && !data.schedule) || (schedule && data.schedule)) {
            hasSchedule = true;
          }

          if (!data.driver || !data.booking || !hasCar || !hasSchedule) {
            return Promise.reject(this.$i18n.t('modalVehicleDelivery.texts.toCancelDelivery'));
          }

          return true;
        })
        .catch(err => {
          this.$log.logError(err);
          return Promise.reject(this.$i18n.t('modalVehicleDelivery.texts.couldNotCancelDelivery'));
        });
    },
    async $_finishBooking() {
      if (!this.booking) {
        throw new Error(this.$i18n.t('modalVehicleDelivery.texts.bookingNotFound'));
      }

      if (!this.booking.pre_transaction_method) {
        throw new Error(this.$i18n.t('modalVehicleDelivery.texts.bondPaymentNotFound'));
      }

      if (!this.booking.transaction_method) {
        throw new Error(this.$i18n.t('modalVehicleDelivery.texts.weekPaymentNotFound'));
      }

      if (!this.cnh_image) {
        throw new Error(this.$i18n.t('modalVehicleDelivery.texts.documentNotInformed'));
      }

      if (!this.contract_image) {
        throw new Error(this.$i18n.t('modalVehicleDelivery.texts.contractNotInformed'));
      }

      if (!this.car_document_image && this.showCarDocument) {
        throw new Error(this.$i18n.t('modalVehicleDelivery.texts.vehicleDocumentNotInformed'));
      }

      if (!this.address_image) {
        throw new Error(this.$i18n.t('modalVehicleDelivery.texts.proofAddressNotInformed'));
      }

      return this.$apollo
        .mutate({
          mutation: this.activate_booking_mutation,
          variables: {
            id: this.booking.id,
            driver: this.driverId,
            plan: this.plan.id,
            has_recurrency: true,
            started_at: this.$moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            car_delivered_at: this.$moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            transaction_method: this.booking.transaction_method.id,
            pre_transaction_method: this.booking.pre_transaction_method.id,
            contract: this.contract_image,
            cnh_doc: this.cnh_image.id,
            car_document: this.car_document_image,
          },
        })
        .then(() => {
          this.$apollo.mutate({
            mutation: this.update_car_document_mutation,
            variables: {
              input: {
                car: this.selected_car,
                document_url: this.car_document_image,
              },
            },
          });
          if (this.onResult) {
            this.$apollo.mutate({
              mutation: APPROVE_DOCUMENTATION,
              variables: {
                documentId: this.onResult.id,
              },
            });
          }

          if (this.deliveryScheduleId) {
            this.$apollo.mutate({
              mutation: this.finally_schedule_delivery_mutation,
              variables: {
                input: {
                  id: this.deliveryScheduleId,
                  car: this.selected_car,
                },
              },
            });
          }
          return true;
        })
        .catch(err => {
          this.$log.logError(err);
          return Promise.reject(this.$i18n.t('modalVehicleDelivery.texts.couldNotLinkThisVehicle'));
        });
    },
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.white-color {
  color: #ffffff;
}

/deep/ .button-list button:nth-child(2) {
  margin-left: 24px;
  margin-right: 24px;
}

/deep/ #driverSignupDelivery {
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
